.vs-sliding-panel-section-container, .vs-sliding-panel-section {
    list-style: none;
    margin-block-end: 0;
    padding-inline-start: 0;
}

.vs-sliding-panel-section-container.vs-expanded ul {
    padding-inline-start: 0;
}

li.vs-sliding-panel-header {
    list-style: none;
    padding-top: 0;
}

.filter-container .vs-react-select__control, .filter-container .vs-react-select-async__control {
    height: fit-content;
}

.filter-container.vs-react-select__value-container {
    padding: 8px 0;
}

div[data-testid="sliding-panel-arrow"] {
    top: 10px;
}

li.vs-sliding-panel-header {
    padding-top: 4px;
}

.vs-sliding-panel-header {
    padding: 10px 15px;
}

.filterPanelController #applyFilter,
.filterPanelController #clearFilter {
    width: 130px;
}