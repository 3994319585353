.validFileSize {
    color: var(--color-text-subdued) !important;
    font-size: 12px !important;
}

.inValidFileSize {
    color: var(--color-text-critical) !important;
    font-size: 12px !important;
}

#bulkUploadContainer .vsx-single-file-upload-button,
#bulkUploadContainer .vsx-single-file-upload-text {
    margin-bottom: 0 !important;
}