.modal-body .vsx-react-select {
    width: 150px;
}

.create-placement-modal .modal-dialog {
    width: fit-content;
}

.create-placement-modal .modal-content {
    top: 0;
    width: 920px;
}

.vsx-tab-label {
    border-right: none !important;
}

.vsx-tab-header {
    margin-left: -4px;
}

.vsx-tab-header-container {
    padding-top: 12px !important;
}

.vsx-tab-body .vsx-react-select {
    width: 270px;
}

.vsx-tab-body .form-control {
    width: 270px;
}

.vsx-tab-body textarea.form-control {
    width: 100%;
}

.vsx-tab-body button {
    width: 270px;
    text-align: left;
}

.ant-picker {
    width: 270px;
}

.vsx-field-help {
    color: #1CB29A;
    font-size: 1.3rem;
    margin-top: -4px;
}

.form-group-required-sign {
    font-size: 1.3rem;
}

.fetch-message {
    color: grey;
    font-size: 1.3rem;
    margin-top: -4px;
    margin-bottom: 0;
}

.record-modal-hr {
    box-sizing: content-box;
    border: 0;
    border-top: 1px solid #e1e1e1;
    height: 0;
    margin-top: 1rem;
    margin-bottom: 1rem;
    overflow: visible;
}

.modal {
    overflow: auto;
}

.create-placement-container .vsx-tab-content.standard {
    padding-left: 0;
    padding-right: 0;

}

.button-spacing {
    margin-right: 10px;
}

.textArea-maxCharacter-notification {
    text-align: end;
    color: grey;
}

.textArea-maxCharacter-notification-transactionid {
    text-align: center;
    margin-left: -8%;
    color: grey;
}

.datepickerError {
    border-color: #e1523d;
    border-radius: 3px;
    box-shadow: 0 0 0 1px rgb(225 82 61);
}

.modal-body label {
    font-weight: var(--font-font-weight-medium);
    margin-bottom: .4rem;
    font-size: 1.3rem
}

.overwrite-legacy-textField input,
.overwrite-legacy-textField .input-error {
    border: 1px solid #c8ccce;
    border-radius: 3px;
    height: 32px;
    line-height: 1.6;
    padding: 3px 10px;
    transition: border-color 0.2s ease-in-out;
}

.input-error,
.overwrite-legacy-textField .input-error {
    border-color: var(--color-background-critical);
    box-shadow: 0 0 4px rgba(225, 82, 61, 0.4);
}

.overwrite-legacy-textField input:focus {
    border-color: #49c5b1;
    box-shadow: 0 0 4px rgba(73, 197, 176, .4);
    outline: 1px solid rgba(73, 197, 176, .2);
}

.vsx-field-help {
    margin-top: .8rem;
}

.vsx-radio-label-content {
    font-weight: 400 !important;
}

.vsx-field-content {
    margin-top: 2px;
}

.vsx-react-select-input {
    margin: 0 !important;
}