
.bulkUploadAndDownloadContainer .tippy-content > div >ul {
    margin: 3px 0;
    padding: 0;
}

#singleTabExcelButton,
#multiTabExcelButton,
#bulkUploadButton {
    list-style: none;
    padding: 8px;
    cursor: pointer;
}

.bulkUploadAndDownloadContainer .vsx-dropdown {
    min-width: 150px!important;
    border-radius: 5px;
    top: 10px;
    left: -115px;
    padding-left: 0!important;
    position: fixed;
}

#singleTabExcelButton:hover,
#multiTabExcelButton:hover,
#bulkUploadButton:hover {
    background-color: var(--color-background-hovered);
}