/*this is required for old campaigns page to come up, remove once new nav is live*/
.vs-page-content {
    flex-basis: calc(100vw);
}

#globalSearch {
    display: flex;
    align-items: center;
}

.vsx-expanding-input-button {
    margin-left: 4px;
}

/* css to remove dual horizontal scrollbar in datatable*/
.campaign-datatable .vsx-flexpage {
    overflow: hidden;
}

/* css to remove global search focus*/
.campaign-datatable #globalSearch input[type='text'] {
    outline: none;
    box-shadow: none;
    margin-bottom: 0;
}