[id$="-baseline-radio"] {
    width: 160px !important;
}

#custom-baseline-input {
    width: 270px !important;
    margin-bottom: .8rem;
}

.baseline-error {
    border: solid #e1523d 1px;
    box-shadow: 0 0 4px rgb(225 82 61 / 40%);
    padding: 8px;
    border-radius: 3px;
    margin-right: 12px;
}

.baseline-warning {
    margin-right: 12px;
}

.baseline-no-error {
    border: none;
}
