#removeModal .modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
}

.header-rm {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.placement-details {
    font-weight: bold;
}